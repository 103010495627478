import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
var container = document.getElementById('root');
var root = createRoot(container);
root.render(_jsx(App, {}));
// if (container) {
//     const root = createRoot(container as Element);
//
//     const el = document.getElementById("mainPinLogin");
//
//     if (el) {
//         root.render(<PinLogin el={el}/>)
//         //root.render(<App />);
//         window.addEventListener('loginSuccess', (event: Event) => {
//             const customEvent = event as CustomEvent;
//             console.log('Login button clicked event received:', customEvent.detail.message);
//
//             root.render(<App />)
//             el.remove()
//         });
//     }
// }
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
